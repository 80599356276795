<template>
    <div id="landingRoot">
        <div class="headerContainer">
            <div class="lpHeaderTop">
                <div class="topLogo"></div>
                <div class="regButton desktop-only">
                    <button @click="$router.push('#contactFormAnc')" class="button thinButton">
                        Register Now
                    </button>
                </div>
            </div>
            <div class="lpHeaderBottom">
                <div>
                    <img clss="mainTitle" src="../../assets/landing/google-otc/maintitle.svg">
                </div>
                <div class="headerText">
                    Join us for an exclusive, invite-only experience designed for tech leaders who drive transformation.
                </div>
                <div class="headerBold">
                    May 6, 2025 <span class="desktop-only">|</span> <span class="mobileBlock">Flight Simulator Squadron,
                    </span>Cinema City, Glilot.
                </div>
                <div class="regButton dponly">
                    <button @click="$router.push('#contactFormAnc')" class="button thinButton">
                        Register Now
                    </button>
                </div>
            </div>

        </div>
        <div class="mainContainer">
            Hosted inside the squadron of F-16 and F-35 flight simulators,
            this one-of-a-kind event blends strategic cloud innovation with the precision and discipline of elite
            aviation.
            <strong>Guided by Google Cloud and Sela's industry experts,</strong> you'll gain insights that redefine
            what's possible in the cloud era.
        </div>
        <div class="agenda">
            <div class="agendaTitle">
                Agenda
            </div>
            <div class="agendaText">
                <strong>09:00-09:45 | Arrival & Breakfast </strong>
                <br />Welcome and networking over a light breakfast.


                <br /><br /><br /><strong>09:45-10:10 | Navigating the Future with Sela and Google Cloud</strong>
                <br>Ishai Ram, EVP Cloud, Sela

                <br /><br /><br /><strong>10:10-10:30 | Mastering the Cloud</strong>
                <br>Google Cloud

                <br /><br /><br /><strong>10:30-12:30 | Flight Experience & Innovation Insights</strong>
                <br>Step into the cockpit for a hands-on flight simulation experience in an F-16 or F-35

                <br /><br /><br /><strong>12:30-13:00 | Decision Making in Times of Uncertainty</strong>
                <br>Fighter pilot, The Squadron

                <br /><br /><br /><strong>13:00-13:45 | Lunch & Networking</strong>
                <br>Enjoy a closing lunch and further discussions with industry peers.

            </div>
        </div>

        <div id="customLP" class="text landingFormContainer">

            <div class="formContainer">
                <div class="formLeft">
                    <a id="contactFormAnc"></a>
                    <span class="formTitle">Ready to soar into the future of cloud innovation? </span>
                    <br /><span class="formSubTitle">Reserve your seat now.</span>
                </div>
                <div class="formRight">
                    <div>
                        <form class="form land-form-wrap" @submit.prevent="sendForm" v-if="formStatus != 'success'"
                            ref="contactForm" id="contactForm">
                            <input-field class="input land-input aiInputField" label="Full Name" :required="true"
                                v-model="form.fullName" @error-message="onError"
                                :validationType="ValidationTypeEnum.AlphaBet"
                                errorText="First name can only contain letters" />
                            <input-field class="input land-input aiInputField" type="email" label="Work Email"
                                :required="true" v-model="form.email" @error-message="onError"
                                :validationType="ValidationTypeEnum.Email" errorText="Invalid Email address" />
                            <input-field class="input land-input aiInputField" label="Phone Number" :required="true"
                                v-model="form.phone" @error-message="onError" :validationType="ValidationTypeEnum.Phone"
                                errorText="Invalid phone number" />
                            <div class="pairOfFields">
                                <input-field class="input land-input aiInputField" label="Company" :required="true"
                                    v-model="form.company" @error-message="onError"
                                    :validationType="ValidationTypeEnum.Required" errorText="Company is required" />
                                <input-field class="input land-input aiInputField" label="Job Title" :required="true"
                                    v-model="form.jobTitle" @error-message="onError"
                                    :validationType="ValidationTypeEnum.Required"
                                    errorText="Company website is required" />
                            </div>
                            <select class="aiInputField" style="display:none" name="region" id="region"
                                v-model="form.region">
                                <option :value="null" disabled>Geographic Region</option>
                                <option v-for="region in $local.websiteSettings.region" :key="region._id"
                                    :value="region.name">
                                    {{ region.name }}</option>
                            </select>

                            <label class="bgl-checkbox-label">
                                <input @error-message="onError" errorText="You have to agree to the privacy policy"
                                    type="checkbox" class="bgl-checkbox" :required="true" v-model="checkboxChecked" />
                                <span class="checkbox">✔</span>
                                <span class="land-checkbox-txt">
                                    I have read and agree to the <a href="https://selacloud.com/legal/privacy-policy"
                                        target="_blank">Privacy Policy</a></span>
                            </label>
                            <button rect :disabled="formStatus === 'sending' || this.hasFalseProp || !checkboxChecked"
                                type="submit" class="button whiteBorderButton fullWidthButton">
                                Register Now
                            </button>
                            <div class="error" v-if="formStatus == 'fail'">
                                <p>An error occurred while submitting the form</p>
                            </div>
                        </form>
                        <div class="thanks" v-if="formStatus == 'success'">
                            <!-- show a customized thank you text. if it includes @FILE_URL@ then replace it with the URL of the PDF-->
                            <div v-if="lp.formThankyouContent && lp.formThankyouContent != ''"
                                v-html="replaceUrlInThankyouText(lp.formThankyouContent)" />

                            <p v-else> <!-- show a default thank you text-->
                                <span class="new-line">Thank you for your interest in this event.
                                    As this event is on an invite-only basis, your registration is currently pending
                                    review. <br>Once approved, you will be informed.</span>
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="video-container">
            <!-- <video autoplay loop muted playsinline>
                <source src="../../assets/landing/sko2025/bgMovie.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video> -->
            <img src="../../assets/landing/google-otc/footerbg.jpg" class="footerbg">
            <img src="../../assets/landing/google-otc/cloudMask.png" class="overlay">
            <div class="overlayText">
                Over the Clouds Starts Now.
            </div>
        </div>
        <div class="otc-footer">
            <img src="../../assets/landing/google-otc/google-sela-logo.png">
        </div>



    </div>
</template>


<script>
import axios from 'axios';

export default {
    metaInfo() {
        return {
            ssrAppId: "sela",
            ...this.seo({
                title: this.lp.title,
                description: this.lp.blurb,
                image: this.event?.thumbnail?.imageURL || this.lp.cover?.imageURL,
            }),
        };
    },
    components: {

    },
    data: () => ({
        now: new Date(),
        formStatus: "",
        form: {
            pageName: "",
            pageId: "",
            fullName: "",
            email: "",
            company: "",
            phone: "",
            jobTitle: "",
            region: null
        },
        checkboxChecked: false,
        ValidationTypeEnum: {
            AlphaBet: /^[a-zA-Z ]+$/,
            Email: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
            Phone: /^(\+)?(?:[0-9-()/.\s?]){6,15}[0-9]{1}$/,
            Required: /.+/
        },
        hasFalseProp: false,
        formErrors: {},
    }),

    watch: {

    },
    computed: {
        lp() {
            const id = "google-otc";
            const lp = this.$local.landingPages?.find((c) => c.slug == id);

            return lp
        },
    },
    mounted() {
        console.log("ver 9.4");
    },
    methods: {
        onError(errorObj) {
            this.formErrors[errorObj.label] = errorObj.validation;

            this.setFalseProp();
        },
        async sendForm() {
            try {
                this.setFalseProp();
                if (this.hasFalseProp) {
                    return; // Stop the form submission if there are errors
                }
                this.formStatus = "sending";
                this.form.pageName = this.lp.name;
                this.form.pageId = this.lp._id;

                await axios.post(
                    '/.netlify/functions/submitLandingDetails',
                    this.form
                );
                this.formStatus = "success";
            } catch (err) {
                console.log(err.response.data);
                this.formStatus = "fail";
            }
        },
        setFalseProp() {
            this.hasFalseProp = Object.values(this.formErrors).includes(false);
        },
        replaceUrlInThankyouText(htmlContent) {
            htmlContent = htmlContent.replace(/@FILE_URL@/g, this.lp.pDFForDownload.imageURL);
            console.log(htmlContent);
            return htmlContent;
        }
    },
    name: "Landing",
};

</script>

<style scoped>
#landingRoot {
    color: #ffffff;
    background-color: #101010;
}

.headerContainer,
.mainContainer,
.landingFormContainer,
.agenda,
.video-container {
    max-width: 1440px;
    width: 100%;
    margin: auto;
}

.lpHeaderTop,
.lpHeaderBottom,
.mainContainer,
.agenda {
    width: 100%;
    padding-left: 155px;
    padding-right: 155px;
}

.headerContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 795px;

    background-image: url('../../assets/landing/google-otc/headerbg.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
}


.lpHeaderTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    height: 90px;
}


.lpHeaderBottom {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", serif;
    font-size: 20px;
    font-weight: 300;
    max-width: 80%;
    height: 65%;
    justify-content: space-around;
    padding-top: 7%;
}

.mainTitle {
    width: 504px;
}

.headerText {
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0.3px;
}

.headerBold {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.topLogo {
    background-image: url('../../assets/landing/google-otc/google-sela-logo.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 264px;
    height: 30px;
}

#landingRoot .button {
    background-color: white;
    color: #2E3033;
    font-family: "Poppins", serif;
    font-size: 16px;
    font-weight: 400;
    padding: 8px;
    min-width: 181px;
    cursor: pointer;
    border: 1px solid #101010;
}

#landingRoot .button:not([rect]) {
    border-radius: 50px;
}


#landingRoot .button:disabled {
    color: grey;
    background-color: #d3d3d3;
    cursor: auto;
}


#landingRoot .button::after {
    content: url('../../assets/landing/google-otc/arrow-right.svg');
    width: 24px;
    height: 24px;
    top: 3px;
    position: relative;
    display: inline-block;
}

#landingRoot .button:disabled::after {
    opacity: 0.6;
}

.mobile-only {
    display: none;
}

.desktop-only {
    display: inline-block;
}


.mainContainer {
    background-image: url('../../assets/landing/google-otc/mainbg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 2147 / 1448;

    font-family: "Lexend";
    font-size: 22px;
    font-weight: 300;
    color: #000000;
    line-height: 45px;
    letter-spacing: 0.3px;
    padding-top: 90px;
}

.agenda {
    display: flex;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 100px;
}

.agendaTitle {
    font-family: "Lexend";
    font-size: 120px;
    font-weight: 200;
    line-height: 60px;
    flex: 50%;
}

.agendaText {
    flex: 50%;
    font-family: "Poppins", serif;
    font-size: 17px;
    line-height: 24px;
    font-weight: 300;
}

#customLP .formLeft {
    justify-content: flex-start;
}

.formTitle {
    font-family: "Lexend";
    font-size: 38px;
    font-weight: 200;
    letter-spacing: 1.3px;
    max-width: 360px;
}

.formSubTitle {
    font-size: 23px;
    font-weight: 300;
    letter-spacing: 1.3px;
    color: var(--light-blue);
}

.new-line {
    font-weight: 200;
    color: var(--light-blue);
}

.video-container {
    position: relative;
    width: 100%;
}

video {
    width: 100%;
    height: auto;
    display: block;
}

.footerbg {
    /* width: 100%; */
    width: 99.8%;
    margin: auto;

    height: auto;
    max-height: 680px;
    display: block;
}

.overlay {
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    pointer-events: none;
}

.overlayText {
    font-family: "Lexend";
    font-size: 75px;
    font-weight: 600;
    color: #ffffff;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

.otc-footer {
    width: 100%;
    height: 163px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.otc-footer img {
    width: 335px;
    height: 42px;
}

@media screen and (max-width: 1200px) {
    .overlayText {
        white-space: wrap;
        text-align: center;
        font-size: 65px;
    }
}

@media screen and (max-width: 991px) {

    .headerContainer {
        background-image: url('../../assets/landing/google-otc/mobileHeaderBG.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 660px;
        height: auto;
    }

    .mainContainer {
        font-size: 17px;
        padding-top: 40px;
        line-height: 28px;
        min-height: 600px;
    }

    .agenda {
        flex-direction: column;
    }

    .lpHeaderBottom {
        max-width: 90%;
        gap: 40px;
    }

    .headerText {
        font-size: 18px;
    }

    .headerBold {
        font-size: 17px;
        line-height: 27px;
    }


    .lpHeaderTop,
    .lpHeaderBottom,
    .mainContainer,
    .agenda,
    #customLP .formRight,
    #customLP .formContainer .formLeft {
        width: 100%;
        padding-left: 35px;
        padding-right: 35px;
    }

    #customLP .formContainer .formRight {
        margin: 0;
        padding-bottom: 100px;
    }


    #customLP .formRight .pairOfFields {
        flex-direction: column;
    }


    #customLP .formRight .pairOfFields .input {
        flex: auto;
    }

    .agendaTitle {
        flex: 100%;
        font-size: 80px;
        line-height: 133%;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .agendaText {
        flex: 100%;
        font-size: 16px;
        line-height: 27px;
    }


    .topLogo {
        background-size: contain;
        width: 219px;
    }

    .agendaTitle {
        font-size: 70px;
    }


    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .mobileBlock {
        display: block;
    }

    .formTitle {
        font-size: 29px;
        line-height: 126%;
        text-align: left;
    }

    .overlayText {
        font-size: 37px;
        line-height: 42px;
        width: 90%;
    }

    .otc-footer {
        height: 82px;
    }

    .otc-footer img {
        width: 167px;
        height: auto;
    }
}


@media screen and (max-width: 767px) {
    .headerContainer {
        flex-direction: column;
        gap: 20px;
    }
}

/* -------------------------- */

.land-form-thanks {
    max-width: 90%;
    margin: auto;
    background: transparent;
    color: white;
    margin-top: 100px;
}


#requestdemo {
    max-width: 1000px;
    height: 1000px;
    margin: auto;
}


#requestdemo {
    scroll-margin-top: 100px;
}


.video {
    margin-right: 34px;
}

.video-shadow {
    box-shadow: rgb(0, 219, 232) 25px 25px;
    width: 675px;
}


.demo-title {
    display: flex;
    margin: auto;
    margin-top: 20px;
    gap: 20px;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.button-container input {
    height: 54px;
    margin: 15px;
}

.demo-title img {
    max-width: 120px;
}

.main-container {
    display: flex;
    flex-direction: column;
}







@media screen and (max-width: 479px) {

    .mainContainer {
        background-image: url('../../assets/landing/google-otc/mainBgMobile.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        aspect-ratio: 645 / 788;
    }


    .mobile-title {
        margin: 20px auto;
        border: 10px solid var(--light-blue);
        padding: 14px;
        width: 90;
        text-align: center;
    }

    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none;
    }

    ::v-deep .btn {
        padding: 12px 22px;
    }
}



@media screen and (max-width: 1200px) {
    .agendaTitle {
        font-size: 80px;
    }
}
</style>