<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="val-sec w1170">



      <div class="val-frame">
        <h1 class="txt54 nomarg">Meet Our Teams</h1>
      </div>

      <div class="titles-wrapper" >
        <div v-for="(team,i) in filteredTeams" :key="i" > 
          <span @click="openTeamDetails(team)" :class="{ 'team-small-head': true, 'isActive': team.isActive }">{{ team.title }}</span>
        </div>
      </div>
        <div v-if="isTeamDetailsOpen" class="team-list">
          <div class="team-box" v-for="member in selectedTeam.teamMembers" :key="member.id">
              <div class="team-profile">
                <img :src="member.profile.imageURL" />
              </div>
              <p class="val-head">{{ member.name }}</p>
              <p class="val-txt">
                {{ member.title }}
              </p>
          </div>

        </div>
      




      <div class="val-frame-bot">
        <p>
          We're always looking for the best for our team
          <br />
        </p>
      </div>

      <router-link :to="buildPath('careers#jobs')" class="btn">Sound good? Join us!</router-link>
    </div>
    <br />
    <p class="credit">Gil Maglad, Elad Gutman | Photographers </p>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";

export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("team"),
        description : getMetadesc("team")
      })
    };
  },   
  data() {
    return {
      selectedJobType: "",
      teams: this.$local.team
      .filter(t => t.title !== 'Technological Team')
      .map(team => ({ ...team, isActive: false })), // Add the isActive property
      isTeamDetailsOpen: true,
      selectedTeam: null, // To store the selected team
      activeTeamIndex: null,
    };
  },
  created() {
  this.teams[0].isActive = true;
  this.selectedTeam = this.teams[0];
  this.activeTeamIndex = 0;
  },
  computed: {
    filteredTeams() {
      return this.teams;
    },
  },
  methods: {
    buildPath,
    openTeamDetails(team) {
    // Deactivate the previously active team
    if (this.activeTeamIndex !== null) {
      this.teams[this.activeTeamIndex].isActive = false;
    }
    
    // Activate the new team
    this.selectedTeam = team;
    team.isActive = true;
    this.activeTeamIndex = this.teams.indexOf(team);
    
    this.isTeamDetailsOpen = true;
  }
  },
  name: "Teams",
};
</script>

<style scoped>
.val-frame-bot {
  margin-top: 50px;
}

.titles-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 70px;
  margin-top: 60px;
}

.team-small-head:hover, .isActive {
  border-bottom: 6px solid var(--dark-blue);
}

.isActive {
  padding-top: 10px;
}

.credit {
  font-weight: 300;
  transform: rotate(90deg);
  position: absolute;
  right: -70px;
  bottom: 100px;
  font-size: 14px;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .credit {
    bottom: -300px;
    font-size: 12px;
    right: -95px;
  }

  .titles-wrapper {
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   margin-bottom: 50px; 
   margin-top: 20px; 
  }

  .team-small-head {
   font-size: 12px; 
  }

  .team-small-head:hover , .isActive{
   border-bottom: 4px solid var(--dark-blue);
  } 
 
} 
</style>


