<template>

  <div class="filter-form-container">
    <div class="filter-formTitle">
      <img src="../assets/ion_filter.svg" alt="Filter" />
      Filter
    </div>

    <form ref="filterForm" id="filterFormId" class="filter-container" :class="crowded ? 'crowded' : ''"
      @submit.prevent="applyFilter()">


      <multiselect @input="submitForm" v-if="showRegions" :showLabels="false" :limit="1"
        :limitText="count => `+ ${count}`" class="multi-select region-select" v-model="form.filterByRegion"
        :options="regions.map(c => c.name)" :multiple="true" :close-on-select="false" :append-to-body="false"
        :searchable="false" :show-labels="false" :hide-selected="false" :required="false" placeholder="Region">
      </multiselect>

      <multiselect @input="submitForm" v-if="showVendors || showAllVendors" :showLabels="false" :limit="1"
        :limitText="count => `+ ${count}`" class="multi-select vendor-select" v-model="form.filterByVendor"
        :options="vendors.map(c => c.name)" :multiple="true" :close-on-select="false" :append-to-body="false"
        :searchable="false" :show-labels="false" :hide-selected="false" :required="false" placeholder="Vendor">
      </multiselect>

      <multiselect @input="submitForm" v-if="showCategories" :showLabels="false" :limit="1"
        :limitText="count => `+ ${count}`" class="multi-select category-select wide" v-model="form.filterByCategory"
        label="title" track-by="title" :options="usedCategories" :multiple="true" :close-on-select="false"
        :append-to-body="false" :searchable="false" :hide-selected="false" :required="false" placeholder="Category">

        <template slot="option" slot-scope="{ option }">
          <div class="filter-multiselect-with-sum">
            <span>
              {{ option.title }}
            </span>
            <span class="multiselect-sum"> ({{ option.numOfPosts }})</span>
          </div>
        </template>
      </multiselect>


      <multiselect @input="submitForm" v-if="showIndustries" :showLabels="false" :limit="1"
        :limitText="count => `+ ${count}`" class="multi-select industry-select wide" v-model="form.filterByIndustry"
        label="title" track-by="title" :options="usedIndustries" :multiple="true" :close-on-select="false"
        :append-to-body="false" :searchable="false" :hide-selected="false" :required="false" placeholder="Industry">

        <template slot="option" slot-scope="{ option }">
          <div class="filter-multiselect-with-sum">
            <span>
              {{ option.title }}
            </span>
            <span class="multiselect-sum"> ({{ option.numOfPosts }})</span>
          </div>
        </template>
      </multiselect>

      <multiselect @input="submitForm" v-if="showCustomers" :showLabels="false" :limit="1"
        :limitText="count => `+ ${count}`" class="multi-select customer-select" v-model="form.filterByCustomer"
        :options="customers.sort((a, b) => a.name.localeCompare(b.name)).map(c => c.name.split(' - ')[0])"
        :multiple="true" :close-on-select="false" :append-to-body="false" :searchable="false" :show-labels="false"
        :hide-selected="false" :required="false" placeholder="Customers">
      </multiselect>


      <div v-if="showSortBy" class="sort-by-field">
        <label class="sortLabel"> Sort by: </label>
        <multiselect @input="submitForm" :showLabels="false" class="multi-select sort-by-field" v-model="form.sortBy"
          :options="sortBy" :multiple="false" track-by="value" label="label" :close-on-select="true"
          :append-to-body="false" :searchable="false" :show-labels="false" :hide-selected="false" :required="false"
          placeholder="Sort by">
        </multiselect>
      </div>

      <div class="widescreenOnly" :class="{ 'loaderAnimation': isSubmitting }"></div>


    </form>

    <br />

    <div v-if="showFreeSearch" class="filter-formTitle">
      <img src="../assets/magnifying-black.svg" alt="Filter" />
      Search
    </div>
    <div v-if="showFreeSearch" class="filter-container search-container">
      <input-field @input="onInputChange" ref="searchByInput" :disabled="disableInput" class="filter-field search-by"
        type="text" :required="false" label="Enter keywords" v-model="form.searchBy"
        @keydown.native.enter="submitForm" />

      <button :disabled="!form.searchBy.trim() || !searchDirty" class="btn forum lowcase" @click="submitForm">
        Search
      </button>

      <div class="filter-on" v-if="form.searchBy.trim()"><a class="clear-link" @click="clearFilter"
          title="Clear the search box">Clear <img src="../assets/delete-icon.svg" class="remove-tag-icon" /></a></div>

      <div class="narrowscreenOnly" :class="{ 'loaderAnimation': isSubmitting }"></div>
    </div>

    <!-- <div class="filter-formTitle filter-formFooter">
      <div class="filter-tag" :key="c._id" v-for="c in activeFilters">
        {{ c.title ? c.title : c }}
        <img src="../assets/delete-icon.svg" class="remove-tag-icon" @click="removeFilter(c.title ? c.title : c)"
          title="Remove from filter" />
      </div>
      <div class="filter-on" v-if="isFilterOn"><a class="clear-link" @click="clearFilter"
          title="Clear all filters">Clear
          All <img src="../assets/delete-icon.svg" class="remove-tag-icon" /></a></div>
    </div> -->


    <!-- <div class="filter-total-found" v-if="this.noResults">No results were found</div>
    <div class="filter-total-found" v-else-if="this.showResultsTotal && filteredTotal > 0">{{ filteredTotal }}
      {{ (filteredTotal == 1 ? "result" : "results") }} found</div> -->


  </div>

</template>

<script>
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import { getCountryFull } from "../utils/geolocation";

export default {
  name: "filter-form",
  components: {
    Multiselect,
  },
  props: {
    dataType: String, // what kind of data we are filtering, e.g. customers, insights etc
    filterPath: String,
    showFreeSearch: Boolean,
    showSortBy: Boolean,
    showRegions: Boolean,
    showVendors: Boolean,
    showAllVendors: Boolean, // including Sela
    showCategories: Boolean,
    showIndustries: Boolean,
    showCustomers: Boolean,
    crowded: Boolean,   // will be sent if the form has a lot of fields
    countryForContent: String,
    disableGeolocationContent: Boolean
  },
  data: () => ({
    form: {
      searchBy: "",
      sortBy: "",
      filterByCustomer: [],
      filterByRegion: [],
      filterByCategory: [],
      filterByVendor: [],
      filterByIndustry: []
    },
    sortBy: [{ label: "Date (Latest first)", value: "_lastUpdatedDate", order: "desc" }, { label: "Date (Oldest first)", value: "_lastUpdatedDate_", order: "asc" }, { label: "Title (A->Z)", value: "title", order: "asc" }, { label: "Title (Z->A)", value: "title_", order: "desc" }],
    filteredData: [],
    noResults: false,
    showResultsTotal: false,
    isSubmitting: false,
    searchDirty: false,
    disableInput: false
  }),
  methods: {
    submitForm() {
      this.isSubmitting = true;
      this.disableInput = true;
      this.searchDirty = false;
      this.applyFilter();
    },
    onInputChange() {
      this.isSubmitting = false;
      this.searchDirty = true;
    },
    getNumberOfItemsByCategory(dataType, categorySlug) {  // returns the number of items (based on dataType) that contain the provided category
      const categoryID = this.$local.blogCategories.find(
        (c) => c.slug == categorySlug
      )._id;

      if (dataType == "customers") {
        return this.$local.customers.filter((b) => b.servicesUsed.find((c) => c.itemRefID === categoryID)).length;
      }
      else if (dataType == "insights") {
        return this.$local.blogPosts.filter((b) => b.category.find((c) => c.itemRefID === categoryID) && !b.isPodcast).length;
      }
    },
    getNumberOfItemsByIndustry(dataType, industrySlug) {  // returns the number of items (based on dataType) that contain the provided industry
      const industryID = this.$local.industryCategories.find(
        (c) => c.slug == industrySlug
      )._id;

      if (dataType == "customers") {
        return this.$local.customers.filter((b) => b.industries.find((c) => c.itemRefID === industryID) && b.customers).length;
      }
    },
    customersFilterFunc(c) {
      return c.customers
    },
    insightsFilterFunc(b) {
      return !b.isPodcast
    },
    async applyFilter() {
      var payload = JSON.parse(JSON.stringify(this.form));
      payload.filterByCategory = this.form.filterByCategory.map(c => c.title);
      payload.filterByIndustry = this.form.filterByIndustry.map(c => c.title);

      await axios.post(
        '/.netlify/functions/' + this.filterPath, payload
      ).then((response) => {
        if (response.data.length > 0) {
          if (this.dataType == "customers")
            this.filteredData = response.data.filter(this.customersFilterFunc);
          else if (this.dataType == "insights")
            this.filteredData = response.data?.filter(this.insightsFilterFunc);
          else  // events etc.
            this.filteredData = response.data

          this.noResults = false;
        }
        else {
          this.noResults = true;
          this.filteredData = [];
        }
        this.showResultsTotal = true;
        this.disableInput = false;
        this.isSubmitting = false;
        this.$emit('refresh-results', this.filteredData);
      }).catch(error => {
        console.error('An error occurred calling the filter service:', error);
      });
    },
    async clearFilter() {
      this.form.searchBy = "";
      this.form.sortBy = this.sortBy[0];
      // this.form.filterByRegion = [];
      // this.form.filterByCategory = [];
      // this.form.filterByVendor = [];
      // this.form.filterByIndustry = [];
      // this.form.filterByCustomer = [];

      if (this.$refs.searchByInput)
        this.$refs.searchByInput.clearInput();

      // this.filteredData = [];
      this.showResultsTotal = false;
      this.submitForm();
      //this.$emit('refresh-results', null);
    },
    async removeFilter(title) { // TODO: better change title to unique _id 
      if (this.form.searchBy == title) {
        this.form.searchBy = "";
        this.$refs.searchByInput.clearInput();
        return;
      }

      let i = this.form.filterByCategory.findIndex(c => c.title == title);
      if (i != -1) {
        this.form.filterByCategory.splice(i, 1);
        return;
      }
      i = this.form.filterByVendor.findIndex(c => c == title);
      if (i != -1) {
        this.form.filterByVendor.splice(i, 1);
        return;
      }
      i = this.form.filterByRegion.findIndex(c => c == title);
      if (i != -1) {
        this.form.filterByRegion.splice(i, 1);
        return;
      }
      i = this.form.filterByIndustry.findIndex(c => c.title == title);
      if (i != -1) {
        this.form.filterByIndustry.splice(i, 1);
        return;
      }
      i = this.form.filterByCustomer.findIndex(c => c == title);
      if (i != -1) {
        this.form.filterByCustomer.splice(i, 1);
        return;
      }
    },
    hideTotalResults() {
      this.showResultsTotal = false;
      this.noResults = false;
    },
  },
  computed: {
    usedCategories() {  // return an array with only the categories that are used by the given datatype (incl the number of items using each category)
      var arr = this.$local.blogCategories.map((i) => {
        return {
          ...i,
          numOfPosts: this.getNumberOfItemsByCategory(this.dataType, i.slug)
        }
      });
      return arr.filter(
        (j) => this.getNumberOfItemsByCategory(this.dataType, j.slug)
      );
    },
    usedIndustries() { // return an array with only the industries that are used by the given datatype (incl the number of items using each industry)
      var arr = this.$local.industryCategories.map((i) => {
        return {
          ...i,
          numOfPosts: this.getNumberOfItemsByIndustry(this.dataType, i.slug)
        }
      });
      return arr.filter(
        (j) => this.getNumberOfItemsByIndustry(this.dataType, j.slug)
      );
    },
    vendors() {
      if (this.showAllVendors)
        return this.$local.vendors;
      else
        return this.$local.vendors.filter(c => c.internal);
    },

    regions() {
      return [...this.$local.branches].sort((a, b) => {
        return a.name > b.name ? -1 : 1;
      });
    },
    customers() {
      return this.$local.customers.filter((c) => c.customers);
    },
    isFilterOn() {
      return this.form.searchBy || this.form.filterByRegion.length > 0 || this.form.filterByCategory.length > 0 || this.form.filterByVendor.length > 0 || this.form.filterByIndustry.length > 0 || this.form.filterByCustomer.length > 0;
    },
    filteredTotal() {
      return this.filteredData.length;
    },
    activeFilters() {
      let res = [...this.form.filterByRegion, ...this.form.filterByVendor, ...this.form.filterByCategory, ...this.form.filterByIndustry, ...this.form.filterByCustomer]
      if (this.form.searchBy)
        res.unshift(this.form.searchBy)
      return res;
    }
  },
  async mounted() {
    const countryFull = await getCountryFull();

    if (this.showRegions && !this.disableGeolocationContent && countryFull && countryFull.toLocaleLowerCase()!="israel")
      this.form.filterByRegion.push(countryFull);

    if (this.showSortBy)
      this.form.sortBy = this.sortBy[0];
  },
  watch: {
    'form.filterByCategory': "hideTotalResults",
    'form.filterByVendor': "hideTotalResults",
    'form.filterByRegion': "hideTotalResults",
    'form.filterByIndustry': "hideTotalResults",
    'form.filterByCustomer': "hideTotalResults"
  },

};
</script>


<style scoped></style>
