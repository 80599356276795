<template>
  <div id="appContainer">
    <div class="mainContainer">
      <img src="../../assets/landing/ebc2025/desktop.jpg" class="ebcDesktop" />
      <img src="../../assets/landing/ebc2025/mobile.jpg" class="ebcMobile" />

      <!-- Top Menu -->
      <a class="menuLink link sundayLink dtOnly" title="Sunday" href="#sunday"> </a>
      <a class="menuLink link mondayLink dtOnly" title="Monday" href="#monday"> </a>
      <a class="menuLink link tuesdayLink dtOnly" title="Tuesday" href="#tuesday"> </a>
      <a class="menuLink link wednesdayLink dtOnly" title="Wednesday" href="#wednesday"> </a>


      <!-- Top Menu Anchors -->
      <div id="sunday" class="ebcAnchor"></div>
      <div id="monday" class="ebcAnchor"></div>
      <div id="tuesday" class="ebcAnchor"></div>
      <div id="wednesday" class="ebcAnchor"></div>

      <a class="link googleOfficeLink" title="Google Office" href="https://maps.app.goo.gl/7RNY5vxyJ4aCdA7K8"
        target="_blank"> </a>

      <a class="link dinnerLink" title="Dinner Event" href="https://maps.app.goo.gl/L4S6Wsz7LkcT61oa7" target="_blank">
      </a>

      <a class="link flightBoardLink" title="Flight Board"
        href="https://www.london-luton.co.uk/flights/flight-detail?fltnmbr=6H115" target="_blank"> </a>

      <a class="link aiPlaygroundLink" title="Google AI Playground" href="https://maps.app.goo.gl/3xk58dsRoHt1qMwm9"
        target="_blank"> </a>

      <a class="link flightBoardLink2" title="Flight Board"
        href="https://www.london-luton.co.uk/flights/flight-detail?fltnmbr=6H116" target="_blank"> </a>

      <div @click="toggleMenu" :class="{ menuopen: isMenuOpen }" id="hamburger-icon">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>

      </div>
      <ul :class="{ menuopen: isMenuOpen }" class="mobile-menu">
        <li><a title="Sunday" href="#sunday">Sunday</a></li>
        <li><a title="Monday" href="#monday">Monday</a></li>
        <li><a title="Tuesday" href="#tuesday">Tuesday</a></li>
        <li><a title="Wednesday" href="#wednesday">Wednesday</a></li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    country: String
  },
  components: {

  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: "Executive Briefing Center London",
        description: "Executive Briefing Center London",
        image: "https://sfo2.digitaloceanspaces.com/bagel/sela/og.jpg",
      }),
    };
  },
  computed: {

  },
  data: () => ({
    isMenuOpen: false
  }),
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  },
  name: "EBC2025",
};
</script>

<style scoped>
#appContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #19191b;
}

.menuLink {
  background-color: white;
  top: 0.1%;
  height: 0.8%;
  opacity: 0;
}

.link {
  position: absolute;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border: 1px solid #19191b;
  /* so it doedsn't go through blue... */
  opacity: 0;
}

.sundayLink {
  left: 58%;
  width: 6%;
}

.mondayLink {
  left: 66%;
  width: 6%;
}

.tuesdayLink {
  left: 73.5%;
  width: 6.5%;
}

.wednesdayLink {
  left: 81.5%;
  width: 8.5%;
}


.googleOfficeLink {
  left: 9.5%;
  width: 30%;
  top: 47.05%;
  height: 0.8%;
  /* opacity:0.5;
  border: 1px solid red; */
}

.dinnerLink {
  left: 52.5%;
  width: 30%;
  top: 57.05%;
  height: 0.7%;
  /* opacity:0.5;
  border: 1px solid purple */
}

.flightBoardLink {
  left: 10%;
  width: 30%;
  top: 31.35%;
  height: 0.8%;
  /* opacity:0.5;
  border: 1px solid yellow; */
}

.flightBoardLink2 {
  left: 9.5%;
  width: 32%;
  top: 89.65%;
  height: 0.8%;
  /* opacity:0.5;
  border: 1px solid green; */
}

.aiPlaygroundLink {
  left: 9.5%;
  width: 33%;
  top: 76.25%;
  height: 0.8%;
  /* opacity:0.5;
  border: 1px solid blue; */
}



.mainContainer {
  padding: 0;
  margin: 0;
  position: relative;
  display: inline-block;
}

.ebcMobile {
  display: none;
}

.ebcDesktop {
  width: 100%;
  max-width: 1441px;
}

.ebcAnchor {
  /* border: 10px solid red; */
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 10px;
  scroll-margin-top: -10px;
}


#sunday {
  top: 26%;
}

#monday {
  top: 43%;
}

#tuesday {
  top: 71.7%;
}

#wednesday {
  top: 85.4%;
}

#hamburger-icon {
  display: none;
}


@media screen and (min-width: 700px) {
  .link:hover {
    border: 1px solid #00d8e7;
    opacity: 1;
  }

  .menuLink:hover {
    opacity: 0.2;
    border: none;
  }

  .googleOfficeLink:hover,
  .dinnerLink:hover {
    border: 1px solid #447de8;
  }

  .mobile-menu {
    display: none !important;
  }

}



@media screen and (max-width: 700px) {

  #sunday {
    top: 22.3%;
  }

  #monday {
    top: 40.2%;
  }

  #tuesday {
    top: 71.4%;
  }

  #wednesday {
    top: 85.4%;
  }

  .ebcMobile {
    display: block;
    width: 100%;
    max-width: 441px;
  }

  .ebcDesktop,
  .dtOnly {
    display: none;
  }



  .googleOfficeLink {
    left: 4%;
    width: 91%;
    top: 42.45%;
    height: 0.4%;
  }

  .dinnerLink {
    left: 4%;
    width: 91%;
    top: 54.1%;
    height: 0.4%;
  }


  .flightBoardLink {
    left: 4%;
    width: 91%;
    top: 24.9%;
    height: 0.4%;
  }


  .aiPlaygroundLink {
    left: 4%;
    width: 91%;
    top: 73.8%;
    height: 0.4%;
  }

  .flightBoardLink2 {
    left: 4%;
    width: 91%;
    top: 88.3%;
    height: 0.4%;
  }



  #hamburger-icon {
    margin: auto 0;
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0.27%;
    right: 7%;
  }

  #hamburger-icon>.bar1,
  #hamburger-icon>.bar2,
  #hamburger-icon>.bar3 {
    width: 35px;
    height: 3px;
    background-color: #ffffff;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 2px;
  }

  #hamburger-icon {
    display: block;
  }

  .menuopen .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
  }

  .menuopen .bar2 {
    opacity: 0;
  }

  .menuopen .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -8px);
  }

  .menuopen.mobile-menu {
    height: calc(100vh - 1%);
    box-shadow: 1px 6px 10px 0px #141414;
  }

  .mobile-menu a {
    color: #101010;
    transition: all 0.3s ease-out;
  }

  .menuopen.mobile-menu a {
    color: #cacaca;
  }

  .mobile-menu {
    background-color: #101010;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: flex-start;
    z-index: 99;
    position: absolute;
    top: 0.73%;
    left: 0;
    height: 0;
    width: 100%;
    cursor: default;
    transition: all 0.3s ease-out;
    overflow: hidden;

    background-image: url(../../assets/landing/ebc2025/menubg.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
  }


  ul.mobile-menu {
    list-style: none;
    padding-left: 0;
  }

  ul.mobile-menu,
  ul.mobile-menu li {
    background-color: #101010;
  }

  ul.mobile-menu li:first-child {
    margin-top: 100px;
  }

  .mobile-menu a {
    font-size: 38px;
    font-weight: 100;
  }
}
</style>
