<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="course-page-wrapper">
      <section class="section-wrapper">
        <div class="title-box-wrapper">
          <h1 class="title-course-name">{{ course.Name }}</h1>
        </div>
      </section>
      <div class="limited-content-wrapper topic-wrapper">
        <div class="course-list-wrapper">
          <div class="course-list-column">
            <div class="container card-container" @click="toggleExtraDetails(item.Id)" v-for="item in syllabus.slice(0, (course.syllabus.length / 2) + course.syllabus.length%2)" :key="item.Id">

              <div class="course-date-message">
                <div :class="isClicked(item) ? 'course-date-message-div-clicked' : 'course-date-message-div'">
                  <div v-if="!item.Courses.length">TBD</div>
                  <div v-else>
                    <p>{{ item.Courses[0].Days[0].trim().substring(0, 2) }}</p>
                    <p>{{ getMonth(item.Courses[0].Days[0].trim().substring(3, 5)) }}</p>
                  </div>
                </div>
              </div>
              <div :class="isClicked(item) ? 'course-card-active' : 'course-card'">
                <div class="title-duration-wrapper">
                  <div class="course-title">{{ item.Title }}</div>
                  <div class="opening-soon-container">
                    <div class="course-duration">
                      {{ item.Duration }}
                      <span v-html="item.Duration == 1 ? 'meeting' : 'meetings'"></span>
                    </div>
                    <div class="course-opening-soon" v-if="item.Courses.length">Opening soon</div>
                  </div>
                </div>
                <div :class="isClicked(item) ? 'course-extra-details-clicked' : 'course-extra-details'">
                  <div>
                    <span>Course Duration: </span>
                    <strong>{{ item.Duration }} <span v-html="item.Duration==1 ? 'meeting' : 'meetings'"></span>
                    </strong>
                  </div>
                  <div>
                    <span>Course Code: </span>
                    <strong>{{ item.Code }}</strong>
                  </div>
                  <span>Upcoming opening dates: </span>
                  <strong v-if="!item.Courses.length">To Be Determined</strong>
                  <div v-else class="dateList-wrapper">
                    <div class="calendar-cube" v-for="c in item.Courses" :key="c.id">
                      <div class="month-wrapper">
                        {{ getMonth(c.Days[0].trim().substring(3, 5)) }}
                      </div>
                      <div class="day-wrapper">
                        {{ c.Days[0].trim().substring(0, 2) }}
                      </div>
                    </div>
                  </div>
                  <div class="extra-details-card-buttons">
                    <router-link class="btn mt30px lowcase" 
                    :to="buildPath('coursecatalog/c/' + item.Code)">
                      <p class="bold nomarg scrollMargin">Explore and Enroll</p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="course-list-column">
            <div class="container card-container" @click="toggleExtraDetails(item.Id)" v-for="item in syllabus.slice( (course.syllabus.length / 2) + course.syllabus.length%2)" :key="item.Id">

              <!-- TODO: convert to component and reuse for both columns -->
              <div class="course-date-message">
                <div :class="isClicked(item) ? 'course-date-message-div-clicked' : 'course-date-message-div'">
                  <div v-if="!item.Courses.length">TBD</div>
                  <div v-else>
                    <p>{{ item.Courses[0].Days[0].trim().substring(0, 2) }}</p>
                    <p>{{ getMonth(item.Courses[0].Days[0].trim().substring(3, 5)) }}</p>
                  </div>
                </div>
              </div>
              <div :class="isClicked(item) ? 'course-card-active' : 'course-card'">
                <div class="title-duration-wrapper">
                  <div class="course-title">{{ item.Title }}</div>
                  <div class="opening-soon-container">
                    <div class="course-duration">
                      {{ item.Duration }}
                      <span v-html="item.Duration == 1 ? 'meeting' : 'meetings'"></span>
                    </div>
                    <div class="course-opening-soon" v-if="item.Courses.length">Opening soon</div>
                  </div>
                </div>
                <div :class="isClicked(item) ? 'course-extra-details-clicked' : 'course-extra-details'">
                  <div>
                    <span>Course Duration: </span>
                    <strong>{{ item.Duration }} <span v-html="item.Duration==1 ? 'meeting' : 'meetings'"></span>
                    </strong>
                  </div>
                  <div>
                    <span>Course Code: </span>
                    <strong>{{ item.Code }}</strong>
                  </div>
                  <span>Upcoming opening dates: </span>
                  <strong v-if="!item.Courses.length">To Be Determined</strong>
                  <div v-else class="dateList-wrapper">
                    <div class="calendar-cube" v-for="c in item.Courses" :key="c.id">
                      <div class="month-wrapper">
                        {{ getMonth(c.Days[0].trim().substring(3, 5)) }}
                      </div>
                      <div class="day-wrapper">
                        {{ c.Days[0].trim().substring(0, 2) }}
                      </div>
                    </div>
                  </div>
                  <div class="extra-details-card-buttons">
                    <router-link class="btn mt30px lowcase" 
                    :to="buildPath('/coursecatalog/c/' + item.Code)">
                      <p class="bold nomarg scrollMargin">Explore and Enroll</p>
                    </router-link>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div>
          <router-link class="btn-course-back" :to="buildPath('/coursecatalog/')">
            <a class="btn lowcase">Back to All Fields</a>
          </router-link>
        </div>
      </div>

      <div class="solutions-schedule-call-wrapper">
        <h2>Can't find the course that you are looking for?<br> Contact us so we can create a tailor-made course just for
          you</h2>
        <a class="btn lowcase" href="../contact">Order a Tailor-Made Course</a>
      </div>


    </div>

    <botfooter  :country="country"></botfooter>
  </div>
</template>
  
<script>

import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.course.Name,
        description : this.course.Name
      })
    };
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "CoursePage",
  data() {
    return {
      currentlyClickedId: 0,
      dateMessage: [],
    };
  },
  methods: {
    buildPath,
    isClicked(item) {
      return this.currentlyClickedId==item.Id;
    },
    toggleExtraDetails(courseId) {
      this.currentlyClickedId = (this.currentlyClickedId == courseId) ? 0 : courseId;
    },
    openDateMessage(dateMsg) {
      if (!this.dateMessage[dateMsg]) {
        this.$set(this.dateMessage, dateMsg, true);
      } else {
        this.$set(this.dateMessage, dateMsg, false);
      }
    },
    getMonth(month) {
      const monthMap = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      };
      return monthMap[month] || "";
    },
  },
  computed: {
    course() {
      const { slug } = this.$route.params;
      return this.$local.courses.find((c) => c.slug === slug);
    },
    syllabus() {
      const arr1 = this.course.syllabus.filter(c => c.Courses.length);
      const arr2 = this.course.syllabus.filter(c => !c.Courses.length);
      return arr1.concat(arr2);
    },
    
  },
};
</script>
  
<style>
.course-page-wrapper {
  padding-top: 10px;
  max-height: -moz-fit-content;
  max-height: fit-content;
  min-height: calc(100vh - 92px - 252px);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: auto;
}

.title-box-wrapper {
  width: 90%;
  border: 14px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-color: #f191a1 !important;
  direction: ltr;
  display: flex;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 1000px;
  min-width: 250px;
  overflow: visible;

}

.section-wrapper {
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  width: 1170px;
  max-width: calc(100vw- 2rem);
  margin: 5rem 1rem;

}

.course-list-wrapper {
  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
  direction: ltr;
  justify-items: center;
  gap: 4rem;
  font-family: Noto Sans Hebrew, sans-serif !important;
  font-size: 18px;
}

.topic-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.course-list-column {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.container {
  display: flex;
  justify-items: flex-start;
}

.card-container {
  cursor: pointer;
}

.course-date-message {
  width: 44px;
}



.course-date-message-div, .course-date-message-div-clicked {
  height: 100%;
}

.course-date-message-div div, .course-date-message-div-clicked div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 5px;
  font-size: 18px;
  height: 100%;
  line-height: 1rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.course-date-message-div {
  border-bottom: 14px solid var(--pink);
}

.course-date-message-div-clicked {
  border: none;
}

.course-date-message p {
  display: flex;
  flex-direction: column;
  margin: 0;

}

.course-date-message.expand {
  border: none;
}

.title-course-name {
  font-size: 3.1rem;
  background-color: #fff;
  width: 150%;
  max-width: calc(100vw - 2rem);
  font-weight: 700;
  text-align: center;
  padding: 1rem;
}

.course-card {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  width: 400px;
  transform: translateX(-45px);
  background-color: #fff;
  transition: all .2s ease-out;
  width: auto;
}


.course-card-active {
  transform: translateX(0px);
}

.course-card.expand {
  border: none;
}

.title-duration-wrapper {
  border-left: 14px solid;
  border-color: var(--pink);
  min-height: 70px;
}

.course-title {
  text-align: start;
  padding-left: 10px;
  font-weight: 700;
  direction: ltr;
  max-width: 385px;
}

.opening-soon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.course-opening-soon {
  display: flex;
  align-items: baseline;
  gap: 0.2rem;
  padding-left: 20px;
  font-style: italic;
  font-size: 16px;

}

.course-opening-soon::before {
  content: "\1F551";
  font-style: normal;
}

.course-duration {
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
}


.course-extra-details, .course-extra-details-clicked {
  background-color: #fff !important;
  position: relative;
  overflow: hidden;  
  transition: all .2s ease-out, border .1s;
  margin-left: -42px;
  cursor: auto;
}

.course-extra-details {
  pointer-events: all;
  max-height: 0;
  border: 14px solid var(--pink);
  border-top: 0 solid var(--pink);
  border-bottom: 0 solid var(--pink);
  padding: 0 15px;
}

.course-extra-details-clicked {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  gap: 0.5rem;
  border: 14px solid var(--pink);
  padding: 17px 17px;
}

.dateList-wrapper {
  display: flex;
  gap: 0.5rem;
  padding-top: 5px;
}

.calendar-cube {
  border: 1px solid;
  height: 4rem;
  width: 4rem;
  display: grid;
  grid-template-rows: 1fr 2fr;
  align-items: center;
  text-align: center;
  font-weight: 700;
}

.month-wrapper {
  padding-bottom: 0px;
  padding-top: -5px;
  width: 100%;
  border-bottom: 1px solid;
  background-color: var(--pink) !important;
}

.day-wrapper {
  text-align: center;
  font-weight: 700;
  align-items: center;
}

.extra-details-card-buttons {
  margin-top: -12px;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.btn-course-back {
  background: var(--black);
  text-decoration: none;
  color: White;
  transition: 200ms all ease;
  cursor: pointer;
  margin-top: 40px;
}

.btn-course-back:hover {
  filter: brightness(80%);
}

.solutions-schedule-call-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem auto 6rem auto;
}

.solutions-schedule-call-wrapper h2 {
  text-align: center;
}

.solutions-schedule-call-wrapper .btn {
  max-width: 400px;
  margin-top: 20px;
}


@media screen and (min-width:967px) {
  .container:hover .course-card {
    transform: translateX(0px);
  }


  .course-extra-details, .course-extra-details-clicked {
    min-width: 420px;
  }  
}

@media screen and (max-width:967px) {


  .course-extra-details, .course-extra-details-clicked {
    margin-left: 0;
    max-width: 100%;
  }

  .course-page-wrapper {
    padding-top: 40px;
    min-height: calc(100vh - 40px - 252px);
  }

  .course-list-wrapper {
    display: flex;
    flex-direction: column;
    direction: ltr;
    justify-items: center;
    font-family: Noto Sans Hebrew, sans-serif !important;
    font-size: 18px;
    margin-bottom: 40px;
    gap: 0;
  }

  .title-box-wrapper {
    width: 90%;
  }

  .section-wrapper {
    width: 100%;
    max-width: 100%;
    margin: 2rem 1rem;
  }

  .title-course-name {
    font-size: 32px;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .course-date-message {
    padding-left: 35px;
    padding-bottom: 3%;
    margin-top: 80px;
    height: 20%;
    transition: opacity 2s ease-in-out;
    display: none;
  }

  .course-date-message-div {
    border: none;
  }

  .course-card {
    transform: translateX(0px);
  }

  .opening-soon-container {
    display: flex;
    flex-direction: row;
  }

  .course-extra-details {
    width: 100%;
  }

  .btn-course-back {
    margin-top: 30px;
    margin-left: 10px;
  }

  .btn-course-back:hover {
    background: var(--black);
  }
}
</style>