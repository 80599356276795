<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="gray-back top">
      <div class="tech-top-wrap">
        <div class="tech-top-img">
          <img :src="vendor.cover.imageURL" v-bind:alt="vendor.cover.altText ? vendor.cover.altText : null" />
        </div>
        <div class="tech-top-frame">
          <img height="130" :src="vendor.mSPBadge.imageURL" />
          <h1 class="txt54" style="margin-top: 0">
            {{ vendor.title }}
          </h1>
          <div class="lighter" v-html="vendor.description"></div>

        </div>
      </div>
    </div>
    <div class="w700 mb60px" v-html="vendor.body"></div>


    <h3 class="point-head center">Our Solutions</h3>
    <div class="w700 service-list no-border">
      <div class="service-item big-link center" v-for="solution of $local.solutions" :key="solution._id">
        <img :src="solution.icon.imageURL" :alt="solution.title" />

        <h3 class="bold nomarg">{{ solution.title }}</h3>
        <p class="big-link-small-txt">{{ solution.subtitle }}</p>
        <router-link
          :to="solution.slug == 'cloud-devops' && vendor.name == 'AWS' ? 'aws/' + solution.slug : 'solutions/' + solution.slug"
          class="big-link-read">Read More</router-link>
      </div>
    </div>

    <div class="w700 mb60px" v-if="vendor.pages.length">
      <div class="sec-frame">
        <div v-html="vendor.cta"></div>
        <div class="cert-logo-wrap">
          <router-link v-for="page in vendor.pages" :key="page._id" class="hoverme" :to="buildPath(`${vendor.slug}/${page.slug}`)">
            <img class="cert-logo" :src="page.badge.imageURL" :alt="page.title" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="w700 mb60px" v-html="vendor.serviceDetails"></div>
    <div class="loc-case-sec mt60px" v-if="$local.customers.filter(
      (c) => c.technology && c.technology.value === vendor.name
    ).length">
      <h3 class="point-head center">Customer Stories</h3>

      <carousel :autoplay="true" :nav="true" :dots="false" :slideTransition="'fade'" :smartSpeed="4000" :items="1"
        :navText="navText" :loop="true">
        <div class="point-wrap list" v-for="client in $local.customers.filter(
          (c) => c.technology && c.technology.value === vendor.name && !c.hide
        )" :key="client._id">
          <div class="point-img-wrap">
            <img :src="client.logo.imageURL" :alt="client.name" class="point-img" />
            <div class="point-frame"></div>
          </div>
          <div class="point-txt-wrap">
            <h3 class="point-head">{{ client.name }}</h3>
            <div class="lighter nomarg" v-html="client.blurb"></div>
            <router-link :to="buildPath(`${client.tech}/customer-story/${client.slug}`)" class="read-more">
              Read More
            </router-link>
          </div>
        </div>
      </carousel>
    </div>

    <div class="sol-end-sec">
      <div class="form-wrap">
            <form
              class="form"
              @submit.prevent="sendForm"
              v-if="formStatus != 'success'"
              ref="contactForm"
              id="contactFormId"
            >
              <div class="form-bg">
                <p class="txt40 bold smoke-text">Leaders Cloud Better</p>
                <p class="txt40 smoke-text">Want to get started?</p>
                <div class="form-side-by form-container">
                  <input-field
                    class="input"
                    label="First Name"
                    :required="true"
                    v-model="form.fullName"
                    @form-errors="handleFormErrors"
                    @error-message="onError"
                    :validationType= "ValidationTypeEnum.AlphaBet"
                    errorText="First name can only contain letters"
                     />
                     <input-field
                    class="input"
                    label="Last Name"
                    :required="true"
                    v-model="form.lastName"
                    @form-errors="handleFormErrors"
                    @error-message="onError"
                    :validationType= "ValidationTypeEnum.AlphaBet"
                    errorText="Last name can only contain letters"
                     />
                </div>
                <div class="form-side-by">
                  <input-field
                    class="input"
                    type="email"
                    label="Email Address"
                    :required="true"
                    v-model="form.email"
                    @error-message="onError"
                    :validationType= "ValidationTypeEnum.Email"
                    errorText="Invalid Email address"
                  />
                  <input-field
                    class="input"
                    label="Phone Number"
                    :required="true"
                    v-model="form.phone"
                    @error-message="onError"
                    :validationType= "ValidationTypeEnum.Phone"
                    errorText="Invalid phone number"
                  />
                </div>
                <div class="form-side-by">
                  <input-field
                    class="input"
                    label="Company"
                    :required="true"
                    v-model="form.company"
                    @error-message="onError"
                    :validationType= "ValidationTypeEnum.Required"
                    errorText="Company is required"
                  />
                  <input-field
                    class="input"
                    label="Company Website"
                    :required="true"
                    v-model="form.website"
                    @error-message="onError"
                    :validationType= "ValidationTypeEnum.Required"
                    errorText="Company website is required"
                  />
                </div>

                <div class="form-side-by">
                  <select
                    class="input error-margin"
                    name="interested"
                    id="interested"
                    v-model="form.interested"
                  >
                    <option :value="null" disabled>Interested In</option>
                    <option
                      v-for="interest in $local.websiteSettings.interested"
                      :key="interest._id"
                      :value="interest.name"
                    >{{ interest.name }}</option>
                  </select>
                  <select
                    class="input error-margin"
                    name="region"
                    id="region"
                    v-model="form.region"
                  >
                    <option :value="null" disabled>Geographic Region</option>
                    <option
                      v-for="region in $local.websiteSettings.region"
                      :key="region._id"
                      :value="region.name"
                    >{{ region.name }}</option>
                  </select>
                </div>
                <textarea
                  placeholder="Comments"
                  class="input big tall error-margin"
                  v-model="form.comments"
                ></textarea>
                <div class="form-btn-wrap">
                  <input
                    :disabled="formStatus === 'sending' || this.hasFalseProp"
                    type="submit"
                    value="Submit"
                    class="btn forum lowcase"
                  />
                </div>
                <div
                  class="error"
                  v-if="formStatus == 'fail'"
                >
                  <p>An error occurred while submitting the form</p>
                </div>
              </div>
            </form>
            <div
              class="form-bg tnx"
              v-if="formStatus == 'success'"
            >
              <p>
                <span class="new-line">Thank you!</span> Your submission has
                been received!
              </p>
            </div>
          </div>
      
    </div>

    <botfooter  :country="country"></botfooter>
    <div class="cutMyBill">
        <img src="../assets/cutMyBillicon.png" @click="scrollToForm" @touchstart="scrollToForm"/>
    </div>  
    
    <SocialMenu :vendor="`${vendor.name}`" :phoneForWhatsapp="vendor.phoneForWhatsapp" :title="`${vendor.title}`" :color="'white'" :size="20"
            class="social-menu" />    
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import carousel from "sh-v-owl-carousel";
import arrowLeft from "../assets/arrow-left.svg";
import arrowRight from "../assets/arrow-right.svg";
import SocialMenu from "../components/SocialMenu.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  components: {
    SocialMenu,
    Topnav,
    Botfooter,
    carousel,
  },
  mounted() {
    
    if (this.$route.path.split("/").pop() === "thank-you")
      this.formStatus = "success";
      
      this.setFalseProp();
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.vendor.metaTitle || "Cloud Better.",
        description: this.vendor.metaDescription,
      }),
    };
  },
  computed: {
    vendor() {
      const slug = this.$route.path.split("/").pop();
      let v = this.$local.vendors.find((v) => v.slug === slug);
      //console.log(v)
      return v;
    },
  },
  methods:{
    buildPath,
    scrollToForm() {
      let contactElement = document.getElementById("contactFormId");
      console.log(`scrollToForm - old school, contactElement=`+contactElement)

      if (contactElement) {
        const y = contactElement.getBoundingClientRect().top + window.scrollY-100;
        console.log(`y=`+y);

        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }
    },
    onError(errorObj) {
      this.formErrors[errorObj.label] = errorObj.validation;
     
      this.setFalseProp();
    },
    async sendForm() {
       try {      
       this.setFalseProp();
        if (this.hasFalseProp) {
         return; // Stop the form submission if there are errors
        }
        this.formStatus = "sending";
        
        await this.$db.collection("contact").post({ ...this.form, vendor: [{ itemRefID: this.vendor._id }] });
        this.formStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      } 
    },
    setFalseProp(){
      this.hasFalseProp = Object.values(this.formErrors).includes(false);
    },
  },  

  data: () => ({
    formStatus:"",
    navText: [
      `<img class="arrow-owl" src="${arrowLeft}" />`,
      `<img class="arrow-owl" src="${arrowRight}" />`,
    ],
    form: {
      fullName: "",
      lastName: "",
      email: "",
      company: "",
      phone: "",
      website: "",
      interested: null,
      region: null,
      comments: "",
      vendor: ""
    }, 
     ValidationTypeEnum:{
      AlphaBet: /^[a-zA-Z ]+$/,
      Email: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
      Phone: /^(\+)?(?:[0-9-()/.\s?]){6,15}[0-9]{1}$/,
      Required: /.+/
    }, 
    hasFalseProp: false,
    formErrors: {},
  }),
  name: "GoogleCloud",
};
</script>

<style>




.cutMyBill {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 132px;
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: center;  
  z-index: 4;
}

.cutMyBill img {
  width: 120px;
  height: 120px;
  transition: all 0.2s;
  cursor: pointer;
}

.cutMyBill img:hover {
  width: 132px;
  height: 132px;
}

.gcp-logo {
  position: absolute;
  width: 300px;
  object-fit: contain !important;
  left: calc(50% - 150px);
  z-index: 9;
}

.form-bg {
  margin-top: 60px;
  margin-bottom: 80px;
}

.form-container {
  margin-top: 20px;
}

.sol-end-sec label {
  text-align: left;
}




@media screen and (max-width: 767px) {

.cutMyBill img {
  width: 75px;
  height: 75px;
  transition: all 0.2s;
  cursor: pointer;
}

.cutMyBill img:hover {
  width: 75px;
  height: 75px;
}

.cutMyBill {
  width: 75px;
  height: 75px;
  right:0;
}

}




</style>
