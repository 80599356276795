<template>
  <div id="appContainer">
    <div class="mainContainer">
      <img src="../../assets/landing/sko2025/desktopNoFooter.jpg" class="ebcDesktop" />
      <img src="../../assets/landing/sko2025/mobileNoFooter.jpg" class="ebcMobile" />

      <!-- Top Menu -->
      <a class="menuLink link day1Link dtOnly" title="Day 1" href="#day1"> </a>
      <a class="menuLink link day2Link dtOnly" title="Day 2" href="#day2"> </a>
      <a class="menuLink link venueLink dtOnly" title="Venue" href="https://maps.app.goo.gl/spdEekD8hwp1P5dA9"
        target="_blank"> </a>


      <!-- Top Menu Anchors -->
      <div id="day1" class="skoAnchor"></div>
      <div id="day2" class="skoAnchor"></div>
      <div id="venue" class="skoAnchor"></div>


      <a class="link venueAddressLink" title="Venue Address" href="https://maps.app.goo.gl/spdEekD8hwp1P5dA9"
        target="_blank"> </a>

      <a class="link venueAddressLink2" title="Dinner Event" href="https://maps.app.goo.gl/spdEekD8hwp1P5dA9"
        target="_blank"> </a>

      <a class="link dinnerLink" title="Dinner Event" href="https://maps.app.goo.gl/X6uGp2CnjcHuATp88" target="_blank">
      </a>



      <div :class="{ showlogo: isMenuOpen }" id="selaLogo"></div>
      <div @click="toggleMenu" :class="{ menuopen: isMenuOpen }" id="hamburger-icon">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>

      </div>
      <ul :class="{ menuopen: isMenuOpen }" class="mobile-menu">
        <li><a @click="toggleMenu" title="day1" href="#day1">Day 1:<br />
            Tuesday | MARCH 18th</a></li>
        <li><a @click="toggleMenu" title="day2" href="#day2">Day 2:<br />
            Wednesday | MARCH 19th</a></li>
        <li><a title="Venue" href="https://maps.app.goo.gl/spdEekD8hwp1P5dA9" target="_blank">Venue Address</a></li>
      </ul>


    </div>

    <div class="bgMovie">
      <video autoplay loop muted playsinline>
        <source src="../../assets/landing/sko2025/bgMovie.mp4" type="video/mp4">
Your browser does not support the video tag.
      </video>
    </div>

    <img class="skoFooter" src="../../assets/landing/sko2025/skoFooter.png" />
  </div>
</template>

<script>


export default {
  props: {
    country: String
  },
  components: {

  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: "SKO / TLV / 2025",
        description: "SKO / TLV / 2025",
        image: "https://sfo2.digitaloceanspaces.com/bagel/sela/og.jpg",
      }),
    };
  },
  computed: {

  },
  data: () => ({
    isMenuOpen: false
  }),
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  },
  name: "SKO2025",
};
</script>

<style scoped>
#appContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #19191b;
  flex-direction: column;
  align-items: center;
}

.menuLink {
  background-color: white;
  top: 0.31%;
  height: 0.72%;
  opacity: 0;

  /* opacity: 0.7; */
}

.link {
  position: absolute;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.4s;
  z-index: 100;
  border: 1px solid #19191b;

  /* so it doedsn't go through blue... */
  opacity: 0;

  /* opacity: 0.7;  */
}

.day1Link {
  left: 36%;
  width: 13%;
}

.day2Link {
  left: 51%;
  width: 13%;
}

.venueLink {
  left: 79.5%;
  width: 14%;
}


.venueAddressLink {
  left: 7.5%;
  width: 26%;
  top: 19.1%;
  height: 0.8%;
  /* border: 1px solid blue; opacity:1; */
}

.venueAddressLink2 {
  left: 7.5%;
  width: 26%;
  top: 63.5%;
  height: 0.8%;
  /* border: 1px solid red; opacity:1; */
}

.dinnerLink {
  left: 58.5%;
  width: 17%;
  top: 49.5%;
  height: 0.7%;
  /* border: 1px solid yellow; opacity:1; */
}




.mainContainer {
  padding: 0;
  margin: 0;
  position: relative;
  display: inline-block;
}

.bgMovie {
  /* border: 1px solid red; */
  max-width: 1441px;
}

.skoFooter {
  max-width: 1441px;
  margin-top: -17.7%;
  width: 100%;
}

.bgMovie video {
  width: 100%;
}

.ebcMobile {
  display: none;
}

.ebcDesktop {
  width: 100%;
  max-width: 1441px;
}

.skoAnchor {
  /* border: 10px solid red; */
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 10px;
  scroll-margin-top: -10px;
}


#day1 {
  top: 13.5%;
}

#day2 {
  top: 58%;
}



#hamburger-icon {
  display: none;
}

#selaLogo {
  display: none;
}


@media screen and (min-width: 700px) {
  .link:hover {
    /* border: 1px solid #00d8e7;
    opacity: 1; */
    opacity: 0.1;
    background-color: white;
  }

  .menuLink:hover {
    opacity: 0.2;
    border: none;
  }

  .dinnerLink:hover {
    border: 1px solid #ffffff;
  }

  .mobile-menu {
    display: none !important;
  }

}



@media screen and (max-width: 700px) {


  #day1 {
    top: 12.5%;
    /* border:5px solid red; */
  }

  #day2 {
    top: 58.3%;
    /* border:5px solid red; */
  }



  .ebcMobile {
    display: block;
    width: 100%;
    max-width: 441px;
  }

  .ebcDesktop,
  .dtOnly {
    display: none;
  }

  .skoFooter,
  .bgMovie {
    max-width: 430px;
  }

  .venueAddressLink {
    left: 9%;
    width: 82%;
    top: 17.5%;
    height: 0.8%;
  }


  .venueAddressLink2 {
    left: 9%;
    width: 82%;
    top: 62.6%;
    height: 0.8%;
  }

  .dinnerLink {
    left: 4%;
    width: 91%;
    top: 48.9%;
    height: 0.8%;
  }






  #hamburger-icon {
    margin: auto 0;
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0.4%;
    right: 7%;
    z-index: 200;
  }

  #selaLogo {
    margin: auto 0;
    cursor: pointer;
    position: absolute;
    top: 0.48%;
    left: 8%;
    width: 65px;
    height: 23px;
    z-index: 200;
    background-image: url(../../assets/landing/sko2025/selaLogo.png);
  }

  .showlogo {
    display: block !important;
  }

  #hamburger-icon>.bar1,
  #hamburger-icon>.bar2,
  #hamburger-icon>.bar3 {
    width: 35px;
    height: 3px;
    background-color: #ffffff;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 2px;
  }

  #hamburger-icon {
    display: block;
  }

  .menuopen .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
  }

  .menuopen .bar2 {
    opacity: 0;
  }

  .menuopen .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -8px);
  }

  .menuopen.mobile-menu {
    height: 100vh;
    box-shadow: 1px 6px 10px 0px #141414;
  }

  .mobile-menu a {
    color: #101010;
    transition: all 0.3s ease-out;
  }

  .menuopen.mobile-menu a {
    color: #ffffff;
  }

  .mobile-menu {
    background-color: #19191A;
    display: flex;
    flex-direction: column;
    gap: 85px;
    align-items: center;
    justify-content: flex-start;
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    cursor: default;
    transition: all 0.3s ease-out;
    overflow: hidden;
    margin-top: 0;
    /* background: linear-gradient(to bottom, #19191A 60%, #2E5EE8 100%); */

    background: url('../../assets/landing/sko2025/menuTrans.png') no-repeat center bottom / 100%, linear-gradient(to bottom, black 70%, #2E5EE8 100%);


    /* background-image: url(../../assets/landing/sko2025/menuTrans.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%; */
  }


  ul.mobile-menu {
    list-style: none;
    padding-left: 0;
  }

  ul.mobile-menu,
  ul.mobile-menu li {
    text-align: center;
    /* background-color: #101010; */
  }

  ul.mobile-menu li:first-child {
    margin-top: 100px;
  }

  .mobile-menu a {
    font-size: 25px;
    font-weight: 100;
  }
}
</style>
